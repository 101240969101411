import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";

import { GET_ADMIN_TEMPLATE_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTemplate";
import ADMIN_TEMPLATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEMPLATE_QUERY";
import {
  AdminBidTemplate,
  TemplateCategory,
} from "@sellernote/_shared/src/types/forwarding/adminTemplate";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import useCheckTemplateData from "../../hooks/useCheckTemplateData";
import useSnackbar from "../../hooks/useSnackbar";

function TemplateDetailSelectModal({
  showsTemplateDetailSelectModal,
  setShowsTemplateDetailSelectModal,
  templateName,
  templateId,
  updateTemplateData,
  type,
  deleteTemplateData,
  templateListItem,
  templateCategory,
}: {
  showsTemplateDetailSelectModal: boolean;
  setShowsTemplateDetailSelectModal: Dispatch<SetStateAction<boolean>>;
  templateName: string;
  templateId: number;
  updateTemplateData: ({
    templateDetailData,
    templateListItem,
  }: {
    templateDetailData: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined;
    templateListItem: AdminBidTemplate;
  }) => void;
  type: "update" | "delete";
  deleteTemplateData: (
    template: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined
  ) => void;
  templateListItem: AdminBidTemplate;
  templateCategory: TemplateCategory;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: getAdminBidTemplateDetail } =
    ADMIN_TEMPLATE_QUERY.useGetAdminBidTemplateDetail();

  const { setIsLoadTemplateData, changeIsLoadTemplateToFalseExceptAll } =
    useCheckTemplateData();

  const handleModalClose = useCallback(() => {
    setShowsTemplateDetailSelectModal(false);
  }, [setShowsTemplateDetailSelectModal]);

  const handleTemplateDetailUpdate = useCallback(() => {
    if (type === "update") {
      getAdminBidTemplateDetail(
        {
          pathParams: { templateId: templateId },
        },
        {
          onSuccess: ({ data }) => {
            setIsLoadTemplateData(templateCategory, true);

            // 전체 템플릿을 불러왔을 때 시 개별항목 불러오기는 초기화
            if (templateCategory === "all") {
              changeIsLoadTemplateToFalseExceptAll();
            }
            updateTemplateData({
              templateDetailData: data,
              templateListItem,
            });
            setShowsTemplateDetailSelectModal(false);
            handleSnackbarOpen("요청에 성공했습니다.", "success");
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
            setShowsTemplateDetailSelectModal(false);
          },
        }
      );
    } else {
      deleteTemplateData([]);
    }
  }, [
    changeIsLoadTemplateToFalseExceptAll,
    deleteTemplateData,
    getAdminBidTemplateDetail,
    handleSnackbarOpen,
    setIsLoadTemplateData,
    setShowsTemplateDetailSelectModal,
    templateCategory,
    templateId,
    templateListItem,
    type,
    updateTemplateData,
  ]);

  const ModalBody = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            {type === "update"
              ? `${templateName}템플릿을 적용합니다.`
              : `${templateName}템플릿 적용을 취소하고 견적서를 초기화합니다.`}
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowsTemplateDetailSelectModal(false)}
            >
              {type === "update" ? "취소" : "돌아가기"}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={handleTemplateDetailUpdate}
            >
              {type === "update" ? "확인" : "초기화"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    handleTemplateDetailUpdate,
    setShowsTemplateDetailSelectModal,
    templateName,
    type,
  ]);

  return (
    <Modal
      isOpened={showsTemplateDetailSelectModal}
      handleClose={handleModalClose}
      modalBody={ModalBody}
    />
  );
}

export default TemplateDetailSelectModal;
