import { useMemo } from "react";

import { GET_ADMIN_ZONE_ADDRESS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminZoneAddress";

export interface ZoneAddressNameListForAutoComplete {
  label: string;
  value: string | null;
}

export default function useZoneAddressNameListForAutoComplete({
  zoneAddressData,
}: {
  zoneAddressData: GET_ADMIN_ZONE_ADDRESS_RES | undefined;
}) {
  const zoneAddressNameListForAutoComplete = useMemo(() => {
    if (!zoneAddressData) {
      return [];
    }
    const userCompanyNameList: ZoneAddressNameListForAutoComplete[] =
      zoneAddressData?.map((zoneAddress) => {
        return {
          label: zoneAddress.roadAddress || zoneAddress.jibunAddress || "",
          value: zoneAddress.roadAddress || zoneAddress.jibunAddress || "",
        };
      });

    return userCompanyNameList;
  }, [zoneAddressData]);

  return { zoneAddressNameListForAutoComplete };
}
