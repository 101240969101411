import { useCallback, useMemo } from "react";

import { GET_ADMIN_BID_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import {
  Currency,
  ItemUnitMeasurement,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFeeData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TradingStatementElem } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import { findExchangeRate } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

export default function useGetNewCurrencyQuotation({
  bidDetail,
  exchangeRateList,
  type,
  /** 이전 가격을 데이터에 추가해줄 지 여부 */
  hasPreviousItemPrice,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
  exchangeRateList: ExchangeRate[];
  type: "draft" | "confirm" | "userQuotation";
  hasPreviousItemPrice?: boolean;
}) {
  const addIsTaxExemptForApplyBidFeeData = (feeData: ApplyBidFeeData[]) => {
    return feeData.map((fee) => {
      return {
        ...fee,
        isTaxExempt: fee.isTaxExempt ?? false,
      };
    });
  };

  const checkHasCustomsClearanceFee = (domesticFee: TradingStatementElem[]) => {
    const customsClearanceFee = domesticFee.find((v) => {
      return v.key === "customsClearanceFee";
    });

    if (customsClearanceFee) {
      return true;
    }
    return false;
  };

  const getNewFreightFee = useCallback(
    (v: ApplyBidFeeData[]) => {
      const newFreightFee = v.map((n: ApplyBidFeeData) => {
        let newItemPrice = n.itemPrice;

        if (bidDetail.locale === "KR") {
          if (n.unitPrice) {
            if (n.currency === "USD") {
              newItemPrice = Math.ceil(
                n.unitPrice *
                  (n.amount ?? 0) *
                  findExchangeRate(exchangeRateList, "USD")
              );
            } else if (n.currency === "EUR") {
              newItemPrice = Math.ceil(
                n.unitPrice *
                  (n.amount ?? 0) *
                  findExchangeRate(exchangeRateList, "EUR")
              );
            } else if (n.currency === "CNY") {
              newItemPrice = Math.ceil(
                n.unitPrice *
                  (n.amount ?? 0) *
                  findExchangeRate(exchangeRateList, "CNY")
              );
            }
            return {
              ...n,
              itemPrice: newItemPrice,
              ...(hasPreviousItemPrice
                ? { previousItemPrice: n.itemPrice }
                : {}),
            };
          }
          return {
            ...n,
            ...(hasPreviousItemPrice ? { previousItemPrice: n.itemPrice } : {}),
          };
        }
        if (n.unitPrice) {
          if (n.currency === "USD") {
            newItemPrice = Number(
              (
                n.unitPrice *
                (n.amount ?? 0) *
                findExchangeRate(exchangeRateList, "USD")
              ).toFixed(2)
            );
          }
          if (n.currency === "EUR") {
            newItemPrice = Number(
              (
                n.unitPrice *
                (n.amount ?? 0) *
                findExchangeRate(exchangeRateList, "EUR")
              ).toFixed(2)
            );
          }
          return {
            ...n,
            itemPrice: newItemPrice,
            ...(hasPreviousItemPrice ? { previousItemPrice: n.itemPrice } : {}),
          };
        }
        return {
          ...n,
          ...(hasPreviousItemPrice ? { previousItemPrice: n.itemPrice } : {}),
        };
      });

      return newFreightFee;
    },
    [bidDetail.locale, exchangeRateList, hasPreviousItemPrice]
  );

  const quotation = useMemo(() => {
    // 초안 견적서 수정
    if (type === "draft") {
      return {
        ...bidDetail.quotationForwarders[0],
        freightFee: getNewFreightFee(
          bidDetail.quotationForwarders[0].freightFee
        ),
      };
    }
    // 초안 견적서 확정할 때 통관대행 수수료 여부로 통관 대행 수수료값 추가
    if (type === "confirm") {
      if (
        bidDetail.containCustoms &&
        // 초안 견적서에서 운임이 있는 구간일 때는 이미 통관대행수수료가 있음 중복 방지를 위해 추가
        !checkHasCustomsClearanceFee(
          bidDetail.quotationForwarders[0].domesticFee
        )
      ) {
        if (bidDetail.locale === "KR") {
          return {
            ...bidDetail.quotationForwarders[0],
            freightFee: getNewFreightFee(
              bidDetail.quotationForwarders[0].freightFee
            ),
            domesticFee: [
              ...bidDetail.quotationForwarders[0].domesticFee,
              {
                key: "customsClearanceFee",
                item: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
                note: "금액 관계없이 3.5만원",
                amount: 1,
                atCost: false,
                currency: "KRW" as Currency,
                itemPrice: 35000,
                unitPrice: 35000,
                itemUnitMeasurement: "B/L" as ItemUnitMeasurement,
                isVAT: true,
                isTaxExempt: false,
              },
            ],
          };
        }
        return {
          ...bidDetail.quotationForwarders[0],
          freightFee: getNewFreightFee(
            bidDetail.quotationForwarders[0].freightFee
          ),
          domesticFee: [
            ...bidDetail.quotationForwarders[0].domesticFee,
            {
              key: "customsClearanceFee",
              item: "CUSTOMS CLEARANCE FEE",
              note: "",
              amount: 1,
              atCost: false,
              currency: "SGD" as Currency,
              itemPrice: 30,
              unitPrice: 30,
              itemUnitMeasurement: "B/L" as ItemUnitMeasurement,
              isVAT: true,
              isTaxExempt: false,
            },
          ],
        };
      }
      return {
        ...bidDetail.quotationForwarders[0],
        freightFee: getNewFreightFee(
          bidDetail.quotationForwarders[0].freightFee
        ),
      };
    }
    // 유저 확정된 견적 수정 시
    return {
      ...bidDetail.quotationsUser[0],
      freightFee: getNewFreightFee(bidDetail.quotationsUser[0].freightFee),
      domesticFee: getNewFreightFee(bidDetail.quotationsUser[0].domesticFee),
      localFee: getNewFreightFee(bidDetail.quotationsUser[0].localFee),
      otherFee: getNewFreightFee(bidDetail.quotationsUser[0].otherFee),
      taxFee: getNewFreightFee(bidDetail.quotationsUser[0].taxFee),
      inlandFee: getNewFreightFee(bidDetail.quotationsUser[0].inlandFee),
    };
  }, [
    bidDetail.containCustoms,
    bidDetail.locale,
    bidDetail.quotationForwarders,
    bidDetail.quotationsUser,
    getNewFreightFee,
    type,
  ]);

  // 비과세 긴급 추가로 데이터 마이그레이션에서 문제가 있을 수 있음 예방하기 위해 데이터 가공
  const quotationWithIsTaxExempt = useMemo(() => {
    return {
      ...quotation,
      freightFee: addIsTaxExemptForApplyBidFeeData(quotation.freightFee),
      domesticFee: addIsTaxExemptForApplyBidFeeData(quotation.domesticFee),
      otherFee: addIsTaxExemptForApplyBidFeeData(quotation.otherFee),
      localFee: addIsTaxExemptForApplyBidFeeData(quotation.localFee),
      taxFee: addIsTaxExemptForApplyBidFeeData(quotation.taxFee),
      inlandFee: addIsTaxExemptForApplyBidFeeData(quotation.inlandFee),
    };
  }, [quotation]);

  return { quotationWithIsTaxExempt };
}
