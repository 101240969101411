import { useState } from "react";
import { Control } from "react-hook-form";
import { Box, Button } from "@mui/material";

import { UPDATE_USER_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidUser";
import { UPDATE_TEAM_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTeam";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  DetailType,
  TeamAndUserDetail,
} from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { UserFileDomain } from "@sellernote/_shared/src/types/forwarding/bid";
import { isNotEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import AdminDetailDescription from "@sellernote/_shared-for-forwarding-admin/src/components/AdminDetailDescription";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import FileName from "@sellernote/_shared-for-forwarding-admin/src/containers/FileName";
import UploadModal from "@sellernote/_shared-for-forwarding-admin/src/containers/UploadModal";

function SettlementInfo({
  detailData,
  control,
  isEditMode,
  type,
}: {
  detailData: TeamAndUserDetail;
  control: Control<UPDATE_TEAM_DETAIL_REQ | UPDATE_USER_DETAIL_REQ>;
  isEditMode: boolean;
  type: DetailType;
}) {
  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [uploadType, setUploadType] = useState("brn");

  const handleUploadModalOpen = (uploadType: UserFileDomain) => {
    return () => {
      setUploadType(uploadType);
      setShowsUploadModal(true);
    };
  };

  const detailDataId = type === "user" ? detailData?.userId : detailData?.id;

  return (
    <>
      <AdminDetailDescription
        title={"회사 정보"}
        isEditMode={isEditMode}
        descriptionValueList={[
          {
            label: "회사명",
            value: detailData.company,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"company"}
                control={control}
                defaultValue={detailData.company}
              />
            ),
            gridSize: 4,
          },
          {
            label: "회사명(영문)",
            value: detailData.engCompany,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"engCompany"}
                control={control}
                defaultValue={detailData.engCompany}
              />
            ),
            gridSize: 4,
          },
          {
            label: "사업자등록번호 (- 생략)",
            value: detailData.BRN,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"BRN"}
                control={control}
                defaultValue={detailData.BRN}
              />
            ),
            gridSize: 4,
          },
          {
            label: "세금계산서 이메일",
            value: detailData.invoiceEmail,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"invoiceEmail"}
                control={control}
                defaultValue={detailData.invoiceEmail}
              />
            ),
            gridSize: 4,
          },
          {
            label: "통장 사본",
            value: (
              <Box>
                {detailData.attachmentBankBookCopy &&
                  isNotEmptyObjectOrArray(detailData.attachmentBankBookCopy) &&
                  detailData.attachmentBankBookCopy.map((v) => {
                    return (
                      <FileName
                        key={v.id}
                        attachment={v}
                        dataType={type}
                        id={type === "team" ? detailData.id : detailData.userId}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("bankBookCopy")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
          {
            label: "사업자등록증",
            value: (
              <Box>
                {detailData.attachmentBRN &&
                  isNotEmptyObjectOrArray(detailData.attachmentBRN) &&
                  detailData.attachmentBRN.map((v) => {
                    return (
                      <FileName
                        key={v.id}
                        attachment={v}
                        dataType={type}
                        id={type === "team" ? detailData.id : detailData.userId}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("brn")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
        ]}
      />

      {showsUploadModal && detailDataId && (
        <UploadModal
          setShowsUploadModal={setShowsUploadModal}
          showsUploadModal={showsUploadModal}
          type={uploadType}
          id={detailDataId}
          dataType={type}
          teamId={type === "team" ? detailData.id : detailData.teamId}
          isPermission={false}
        />
      )}
    </>
  );
}

export default SettlementInfo;
