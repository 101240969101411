import { useMemo } from "react";
import { Grid } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

import CheckPoint from "../components/CheckPoint";
import ExchangeRateInfo from "../components/ExchangeRateInfo";
import ExportCheckPointAndAddition from "../components/ExportCheckPointAndAddition";
import PortStep from "../components/PortStep";
import SummaryHeader from "../components/SummaryHeader";

import ItemList from "./ItemList";

export default function ItemInfoForRead({
  bidDetail,
  exchangeRateList,
}: {
  bidDetail: AdminBidDetail;
  exchangeRateList: ExchangeRate[];
}) {
  const portStepData = useMemo(() => {
    return {
      serviceType: bidDetail.serviceType,
      startAddress: bidDetail.startAddress,
      endCountry: bidDetail.endCountry,
      wareHouse: bidDetail.wareHouse,
      startCountry: bidDetail.startCountry,
      startPort: bidDetail.startPort,
      endPort: bidDetail.endPort,
      startViaPort: bidDetail.startViaPort,
      endViaPort: bidDetail.endViaPort,
      endAddress: bidDetail.endAddress,
      endAddressDetail: bidDetail.endAddressDetail,
      incoterms: bidDetail.incoterms,
      zone: bidDetail.zone,
      startAddressDetail: bidDetail.startAddressDetail,
      isImport: bidDetail.isImport,
    };
  }, [bidDetail]);

  return (
    <Grid container spacing={3}>
      <Grid item container xs={12}>
        <SummaryHeader
          freightType={bidDetail.freightType}
          id={bidDetail.id}
          supply={bidDetail.supply}
        />
      </Grid>

      <Grid item container xs={12}>
        {portStepData && <PortStep portStepData={portStepData}></PortStep>}
      </Grid>

      <Grid item container xs={12}>
        <ItemList
          freightType={bidDetail.freightType}
          serviceType={bidDetail.serviceType}
          itemGroupList={bidDetail.itemGroupList}
        />
      </Grid>

      <Grid item container xs={12}>
        {/* 수출 의뢰데이터 있을 때 체크&부가서비스 항목 */}
        {!bidDetail.isImport && (
          <ExportCheckPointAndAddition
            type="read"
            containerAccessable={bidDetail.containerAccessable}
            containerStuffing={bidDetail.containerStuffing}
            originInland={bidDetail.originInland}
            useVGM={bidDetail.useVGM}
            containCustoms={bidDetail.containCustoms}
            containExportCustoms={bidDetail.containExportCustoms}
            hopeCargoInsurance={bidDetail.hopeCargoInsurance}
            freightType={bidDetail.freightType}
          />
        )}

        {/* 수입 의뢰데이터 있을 때 체크 및 부가서비스 항목 */}
        {bidDetail.isImport && (
          <CheckPoint
            containDomesticFee={bidDetail.containDomesticFee}
            containOceanSurcharge={bidDetail.containOceanSurcharge}
            containLss={bidDetail.containLss}
            containerAccessable={bidDetail.containerAccessable}
            hopeCargoInsurance={bidDetail.hopeCargoInsurance}
            freightType={bidDetail.freightType}
            locale={bidDetail.locale}
            needIORAgency={bidDetail.needIORAgency}
            needFTACertificateAgency={bidDetail.needFTACertificateAgency}
          />
        )}
      </Grid>

      <Grid item container xs={12}>
        <ExchangeRateInfo exchangeRateList={exchangeRateList} />
      </Grid>
    </Grid>
  );
}
