import { useCallback } from "react";

import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

export default function useGetForwardingManagerName() {
  const { data: adminList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const getForwardingManagerName = useCallback(
    (adminId: number) => {
      if (!adminList) return "-";

      return adminList.list.find((v) => v.id === adminId)?.name;
    },
    [adminList]
  );

  return { getForwardingManagerName };
}
