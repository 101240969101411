import { ChangeEvent, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";

import { GET_ADMIN_SHIPMENT_COMMENT_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminCommon";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";

import TextField from "../TextField";
import CommonShipmentCommentList from "./CommonCommentList";
import ManualShipmentCommentList from "./ManualShipmentCommentList";

type CommentPageType =
  /** 의뢰(수동 댓글) */
  | "ManualShipment"
  /** 의뢰(자동 댓글) */
  | "AutoShipment"
  /** 발주 */
  | "order"
  /** 사용자 */
  | "user"
  /** 팀 */
  | "team"
  /** 엽업관리 */
  | "businessManagement";

interface ManualShipmentCommentListProps {
  commentDataList: GET_ADMIN_SHIPMENT_COMMENT_LIST_RES | undefined;
  comment: string;
  onCommentInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /** 댓글 등록요청 함수 */
  onCommentUpdate: () => void;
  /** 댓글 수정요청 함수 */
  onCommentEdit: ({
    message,
    cancelEditMode,
    commentId,
  }: {
    message: string;
    cancelEditMode: () => void;
    commentId: number;
  }) => void;
  /** 댓글 제거요청 함수 */
  onCommentDelete: (commentId: number) => void;
  commentPageType: Extract<CommentPageType, "ManualShipment">;
}

interface OtherCommentListProps {
  commentDataList: Comment[] | undefined;
  comment: string;
  onCommentInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCommentUpdate: () => void;
  onCommentEdit?: never;
  onCommentDelete?: never;
  commentPageType: Extract<
    CommentPageType,
    "order" | "user" | "team" | "businessManagement"
  >;
}

/** 서버에서 의뢰 변경으로 인한 자동 코멘트의 경우에는 입력과 관련된 props가 필요없음 **/
interface AutoCommentListWithOutInputProps {
  comment?: never;
  onCommentInputChange?: never;
  onCommentUpdate?: never;
  onCommentEdit?: never;
  onCommentDelete?: never;
  commentPageType: Extract<CommentPageType, "AutoShipment">;
  commentDataList: Comment[] | undefined;
}

type CommentListProps =
  | ManualShipmentCommentListProps
  | OtherCommentListProps
  | AutoCommentListWithOutInputProps;

export interface TransformedComment extends Comment {
  /** 수정 및 삭제 요청을 위한 id값 */
  commentId: number;
}

function CommentList({
  comment,
  onCommentInputChange,
  onCommentUpdate,
  onCommentEdit,
  onCommentDelete,
  /** 코멘트를 사용하는 페이지 user(사용자), team(팀), order(발주), ManualShipment(수동 의뢰 댓글), AutoShipment(자동 의뢰 댓글) */
  commentPageType,
  commentDataList,
}: CommentListProps) {
  const transformedCommentList = useMemo(() => {
    if (!commentDataList) return undefined;

    if (commentPageType === "ManualShipment") {
      // 수동 의뢰 댓글의 경우 데이터 형식이 달라서 변환
      const manualComments = (
        commentDataList as GET_ADMIN_SHIPMENT_COMMENT_LIST_RES
      ).map((comment) => ({
        /**
         * TODO: 변경내용(레거시 댓글)의 응답 데이터가 변경된 댓글 API 응답과 다르고, key값 매칭이 헷갈리기 때문에 수정이 필요
         * TODO: 파트너 어드민의 경우 accountId가 제대로 오지 않고 있어 name으로 표시(변경내용 API가 수정되면 쉽다도 name으로 변경)
         */
        id:
          APP_NAME === "partner-admin"
            ? comment.admin.name
            : comment.admin.accountId,
        date: comment.updatedAt,
        comment: comment.message,
        commentId: comment.id,
      }));
      return manualComments;
    }

    /** 데이터가 업로드된 순서대로 쌓여서 최신날짜순으로 보여주기 위해 역순으로 변환 */
    return [...(commentDataList as Comment[])].reverse();
  }, [commentPageType, commentDataList]);

  return (
    <Box flex="1">
      <Box>
        <Typography variant="h6" component="div">
          {/* 자동 의뢰 댓글인 경우만 변경 내용으로 표시 */}
          {commentPageType === "AutoShipment" ? "변경 내용" : "댓글"}
        </Typography>
      </Box>

      {/* 수동 댓글일 때 리스트(수정, 삭제 기능이 추가됨) */}
      {transformedCommentList && commentPageType === "ManualShipment" && (
        <ManualShipmentCommentList
          /** commentPageType === "ManualShipment"일때 TransformedComment[]이 보장됨  */
          transformedCommentList={
            transformedCommentList as TransformedComment[]
          }
          onCommentEdit={onCommentEdit}
          onCommentDelete={onCommentDelete}
        />
      )}

      {/* 그 외 댓글일 때 리스트 */}
      {transformedCommentList && commentPageType !== "ManualShipment" && (
        <CommonShipmentCommentList
          transformedCommentList={transformedCommentList as Comment[]}
        />
      )}

      {/* 자동 의뢰 댓글인 경우 인풋이 필요없음 */}
      {commentPageType !== "AutoShipment" && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            sx={{ flex: 1 }}
            value={comment}
            label="댓글 입력"
            fullWidth
            size="small"
            multiline={true}
            onChange={onCommentInputChange}
            onKeyPress={(event) => {
              if (event.key === "Enter" && !event.shiftKey && !!comment) {
                event.preventDefault();
                onCommentUpdate();
              }
            }}
          />

          <Button variant="contained" onClick={onCommentUpdate}>
            저장
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default CommentList;
